<template>
  <div class="content">
    <h1 class="title title--big title--indent title--color">Импорт свободных номеров (EYCA)</h1>
    <div class="content__party content__party--start">
      <UploaderExcel
        @fileUpload="fetchLogs"
        :extensions="['application/vnd.ms-excel', 'text/csv']"
        btnTitle="Загрузить файл (.csv)"
        url="card-free/import/"
      />
      <button
        @click="downloadImport('https://storage.yandexcloud.net/ruy-mkp/import_templates/free_card_import_example.csv', 'free_card_import_example.csv')"
        class="button button--light button--no-indent button--indent-left">
        <img src="@/assets/img/example-import.svg" alt="">
        Скачать пример файла
      </button>
    </div>
    <div class="content__party">
      <div class="table-limiter">
        <div class="table-limiter__text">Количество записей на странице:</div>
        <div class="table-limiter__select">
          <dropdown class="my-dropdown-toggle"
                    :options="limitOptions"
                    :selected="limitSelected"
                    :closeOnOutsideClick="true"
                    @updateOption="updateLimiter"
          >
          </dropdown>
        </div>
      </div>
      <div class="table-limiter">
        <div class="table-limiter__text">
          Показаны записи <span>{{ returnEnd() > 0 ? returnStart() : 0 }} - {{ returnEnd() }}</span> из <span>{{ pagination.count }}</span>
        </div>
      </div>
    </div>
    <v-client-table
      v-if="table.show"
      :data="table.logs"
      :columns="table.columns"
      :options="table.options"
      ref="cardsFreeTable"
      class="table-default">
      <div slot="name" slot-scope="props">
        <button class="table-default__button" @click="downloadImport(props.row.file_url, `${props.row.name}.csv`)">Скачать</button>
      </div>
      <div slot="updated_at" slot-scope="props">
        <span v-if="!props.row.in_process">{{ props.row.updated_at }}</span>
        <div v-else class="loading loading--mini" >
          <div class="loading__body">
            <div class="effect-1 loading__effects"></div>
            <div class="effect-2 loading__effects"></div>
            <div class="effect-3 loading__effects"></div>
          </div>
        </div>
      </div>
    </v-client-table>
    <div class="loading loading--content" id="loading" v-if="!table.show">
      <div class="loading__body">
        <div class="effect-1 loading__effects"></div>
        <div class="effect-2 loading__effects"></div>
        <div class="effect-3 loading__effects"></div>
      </div>
    </div>
    <paginate
      v-if="pagination.pages > 1"
      v-model="pagination.page"
      :page-count="pagination.pages"
      :clickHandler="clickPaginationCallback"
      :prev-text="'<'"
      :next-text="'>'"
      :container-class="'pagination'"
      :page-class="'pagination__item'"
      :page-link-class="'pagination__link'"
      :prev-class="'pagination__prev'"
      :next-class="'pagination__next'"
      :next-link-class="'pagination__link-next'"
      :prev-link-class="'pagination__link-prev'"
    >
    </paginate>
<!--    <SidebarRight-->
<!--      title="Информация об импорте"-->
<!--      :class="{'sidebar-manage&#45;&#45;full': showSidebar}"-->
<!--      @close-sidebar="showSidebar = !showSidebar"-->
<!--    >-->
<!--      <div class="sidebar-card">-->
<!--        <div class="sidebar-card__bottom">-->
<!--          <div class="sidebar-card__item sidebar-card__item&#45;&#45;between">-->
<!--            <b>ID импорта</b> <span>{{ dataSidebar.id }}</span>-->
<!--          </div>-->
<!--          <div class="sidebar-card__item sidebar-card__item&#45;&#45;between">-->
<!--            <b>Имя импорта</b> <span>{{ dataSidebar.name }}</span>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </SidebarRight>-->
  </div>
</template>

<script>
import dropdown from 'vue-dropdowns';
//import SidebarRight from '@/components/SidebarRight';
import UploaderExcel from '@/components/UploaderExcel';

export default {
  name: 'Cards',
  components: { dropdown, UploaderExcel },
  data() {
    return {
      table: {
        logs: [],
        columns: ['id', 'name',  'success_count', 'error_count', 'created_at', 'updated_at'],
        options: {
          headings: {
            id: 'ID',
            name: 'Файл импорта',
            success_count: 'Успешно импортировано',
            error_count: 'Ошибок импорта',
            created_at: 'Начат',
            updated_at: 'Окончен'
          },
          pagination: { show: false },
          sortable: [],
          perPage: 20,
          texts: {
            filter: '',
            filterPlaceholder: 'Поиск по таблице',
            noResults: 'Нет подходящих записей',
            filterBy: '',
            loading: 'Загрузка',
            count: '',
            limit: 'Количество записей на странице:',
            page: 'Страница:'
          },
          rowClassCallback() {
            return 'table-default__row';
          },
          rowAttributesCallback(row) {
            return { 'data-id': row.id };
          }
        },
        show: false
      },
      pagination: {
        pages: 1,
        page: 1,
        count: 0,
        prevLink: '',
        nextLink: ''
      },
      limitSelected: { name: this.$store.state.admin.tableLimit },
      limitOptions: [
        { name: 20 },
        { name: 40 },
        { name: 60 },
        { name: 80 },
        { name: 100 },
      ],
      showSidebar: false,
      dataSidebar: {}
    };
  },
  mounted() {
    this.limitSelected.name = 20;
    this.fetchLogs(1, this.limitSelected.name);
  },
  methods: {
    fetchLogs(page = 1, limit) {
      this.$store.dispatch('admin/cardsFree/GET_IMPORT', { page, limit })
        .then(response => {
          this.table.logs = response.data.results;
          this.table.show = true;
          this.pagination.pages = response.data.pages;
          this.pagination.count = response.data.count;
        })
        .catch(response => {
          for (const key in response.data) {
            if (typeof response.data[key] === 'string') {
              this.$notify({
                type: 'error',
                text: response.data[key]
              });
            } else {
              this.$notify({
                type: 'error',
                text: response.data[key][0]
              });
            }
          }
        });
    },
    updateLimiter(data) {
      this.$store.commit('admin/changeTableLimit', data.name);
      this.$refs.cardsFreeTable.setLimit(this.$store.state.admin.tableLimit);
      this.fetchLogs(1, this.$store.state.admin.tableLimit);
    },
    clickPaginationCallback(page) {
      this.fetchLogs(page, this.$store.state.admin.tableLimit);
    },
    returnStart() {
      return (this.pagination.page * this.table.logs.length) - (this.table.logs.length - 1);
    },
    returnEnd() {
      return this.returnStart() + this.table.logs.length - 1;
    },
    // rowClick(row) {
    //   this.showSidebar = true;
    //   this.dataSidebar = row.row;
    // },
    downloadImport(url, fileName) {
      fetch(url)
        .then(response => response.blob())
        .then(blob => {
          const blobURL = URL.createObjectURL(blob);
          if (fileName.length && url.length) {
            const a = document.createElement('a');
            a.href = blobURL;
            a.download = fileName;
            a.style = 'display: none';
            a.click();
          }
        })
        .catch(response => {
          for (const key in response.data) {
            if (typeof response.data[key] === 'string') {
              this.$notify({
                type: 'error',
                text: response.data[key]
              });
            } else {
              this.$notify({
                type: 'error',
                text: response.data[key][0]
              });
            }
          }
        });
    },
  }
};
</script>

