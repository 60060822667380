<template>
  <vue-clip
    :options="clipOptions"
    :on-added-file="addedFile"
    :on-complete="completeFile"
    :on-sending="sending"
    ref="exc_upl"
    class="uploader uploader--indent-right uploader--min-w"
  >
    <template slot="clip-uploader-body">
      <div class="uploader__files">
        <div class="uploader__file" v-for="file in files" :key="file.id">
          <div class="uploader__content">
            <div
              class="uploader__progress"
              v-if="file.status !== 'error' && file.status !== 'success'"
            >
              <span
                class="uploader__indicator"
                :style="{ width: file.progress + '%' }"
              >
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="uploader__error">{{ message }}</div>
    </template>

    <template slot="clip-uploader-action" slot-scope="params" >
      <div
        v-show="showUploadBtn"
        class="uploader__action"
        :class="{ 'uploader__action--dragging': params.dragging }"
      >
        <div class="uploader__message dz-message">
          <div class="uploader__simple">
            <img src="../assets/img/file/import-icon.svg" alt=""/>
            <span>{{ btnTitle }}</span>
          </div>
        </div>
      </div>
    </template>
  </vue-clip>
</template>
<script>

export default {
  props: ['extensions', 'url', 'btnTitle'],
  data() {
    return {
      clipOptions: {
        url: `${process.env.VUE_APP_API_URL}${this.url}`,
        maxFiles: {
          limit: 1,
          message: 'Вы не можете загружать больше 1 файла'
        },
        acceptedFiles: {
          extensions: this.extensions,
          message: 'Неподходящий формат файла'
        },
        maxFilesize: {
          limit: 20,
          message: 'Размер файла превышает допустимый'
        },
        uploadMultiple: false
      },
      files: [],
      message: '',
      showUploadBtn: true
    };
  },
  methods: {
    addedFile(file) {
      this.files.push(file);
      this.showUploadBtn = false;
    },
    sending(file, xhr) {
      xhr.setRequestHeader('Authorization', `Bearer ${localStorage.getItem('accessToken')}`);
    },
    completeFile(file, status, xhr) {
      //console.log(file);
      if (file.status === 'error') {
        this.message = file.errorMessage;
        this.$refs.exc_upl.removeFile(file);
        this.$refs.exc_upl.files.splice(this.$refs.exc_upl.files.indexOf(file), 1);
        this.files.splice(this.files.indexOf(file), 1);
        return false;
      }
      const response = JSON.parse(xhr.responseText);
      file.addAttribute('link', response.file);
      this.message = '';
      this.removedFile(file);
      this.$emit('fileUpload');
      if (xhr.status === 201) {
        this.showUploadBtn = true;
      }
      this.$notify({
        type: 'success',
        title: 'Внимание!',
        text: 'Файл успешно загружен, данные скоро будут обновлены'
      });
    },
    removedFile(file, event) {
      this.$refs.exc_upl.removeFile(file);
      this.$refs.exc_upl.files.splice(this.$refs.exc_upl.files.indexOf(file), 1);
      this.files.splice(this.files.indexOf(file), 1);
    }
  }
};
</script>

